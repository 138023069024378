import { useState } from 'react';
import { RadioGroup, RadioGroupItem } from '@radix-ui/react-radio-group';
import { cn } from './utils';
import { CheckIcon } from 'lucide-react';

// MARK: Pricing
const frequencies = {
  monthly: { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  annually: { value: 'annually', label: 'Annually', priceSuffix: '/year' },
};

const tiers = [
  {
    name: 'Free',
    id: 'tier-free',
    href: '/v2/login',
    price: { monthly: '$0', annually: '$0' },
    description: 'Try it out for free.',
    features: [
      '1 Editor',
      '5 LMS users included.',
      'AI-Powered Content Generation',
      'Transform PDFs & Videos into E-Learnings',
      'Limited training editing',
    ],
    mostPopular: false,
  },
  {
    name: 'Starter',
    id: 'tier-starter',
    href: '/checkout',
    price: { monthly: '$29', annually: '$299' },
    description:
      'Ideal for individuals and small teams getting started with interactive e-learning course creation. Includes all essential features and support.',
    features: [
      'Everything in Free',
      '20 LMS users included. Additional users $9 per month.',
      'Full AI capabilities',
      'Full training editor',
      'Upload videos',
      'E-Mail support',
    ],
  },
  {
    name: 'Pro',
    id: 'tier-pro',
    href: '/checkout',
    price: { monthly: '$99', annually: '$1099' },
    description:
      'The most popular plan. Perfect for growing teams and businesses looking for advanced features and greater customization.',
    features: [
      'All Starter features',
      '100 LMS users included. Additional users $9 per month.',
      'Advanced interactive elements & assessments',
      'Full branding customization',
      'Enhanced analytics & reporting',
      'Priority support',
    ],
    mostPopular: true,
  },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    href: '/#contact',
    price: { monthly: 'Custom', annually: 'Custom' },
    description:
      'Tailored solutions for large organizations with specific needs. Includes dedicated support and custom integrations.',
    features: [
      'All Pro features',
      'Unlimited usage',
      'Dedicated account manager',
      'Custom integrations & development',
      'Premium support',
    ],
    mostPopular: false,
  },
];

export default function Pricing({ justPlans = false }: { justPlans?: boolean }) {
  const [frequency, setFrequency] = useState<'monthly' | 'annually'>('monthly');

  return (
    <div className={cn('py-24 sm:py-32', justPlans && 'py-0 sm:py-0')}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {!justPlans && (
          <>
            <div className="mx-auto max-w-4xl text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Pricing
              </h2>
              <p className="mt-2 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                Pricing that grows with you
              </p>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-pretty text-center text-lg font-medium text-gray-600 sm:text-xl/8">
              Choose an affordable plan that’s packed with the best features for engaging
              your audience, creating customer loyalty, and driving sales.
            </p>
          </>
        )}
        <div className={cn('mt-16 flex justify-center', justPlans && 'mt-0')}>
          <fieldset aria-label="Payment frequency">
            <RadioGroup
              value={frequency}
              onValueChange={(v: 'monthly' | 'annually') => setFrequency(v)}
              className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
            >
              <RadioGroupItem
                value="monthly"
                className="radix-state-checked:bg-indigo-600 radix-state-checked:text-white cursor-pointer rounded-full px-2.5 py-1 text-gray-500"
              >
                Monthly
              </RadioGroupItem>
              <RadioGroupItem
                value="annually"
                className="radix-state-checked:bg-indigo-600 radix-state-checked:text-white cursor-pointer rounded-full px-2.5 py-1 text-gray-500"
              >
                Annually
              </RadioGroupItem>
            </RadioGroup>
          </fieldset>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
          {tiers.map(tier => (
            <div
              key={tier.id}
              className={cn(
                tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200',
                'rounded-3xl bg-white p-8',
              )}
            >
              <h3
                id={tier.id}
                className={cn(
                  tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                  'text-lg font-semibold leading-8',
                )}
              >
                {tier.name}
              </h3>
              <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-semibold tracking-tight text-gray-900">
                  {tier.price[frequency]}
                </span>
                {tier.price[frequency] !== '$0' && tier.id !== 'tier-enterprise' && (
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    {frequencies[frequency].priceSuffix} per editor
                  </span>
                )}
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={cn(
                  tier.mostPopular
                    ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                    : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                  'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
                )}
              >
                {tier.id === 'tier-free' ? 'Start now' : 'Buy plan'}
              </a>
              <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                {tier.features.map(feature => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      aria-hidden="true"
                      className="h-6 w-5 flex-none text-indigo-600"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
